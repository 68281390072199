<script>
import Layout from "../../_layouts/main"
import PageHeader from "@/components/page-header"
import Document from '../../../components/api/document'

/**
 * Dashboard Component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Document
  },
  data() {
    return {
      title: this.$t('통합배팅내역'),
      apiUrl: process.env.VUE_APP_API_SERVER_URL + 'transactions',
      response: '',
      body: {
        username: { type: 'text', value: '', desc: this.$t('text.api.siteUsername_desc'), required: false },
        seamlessState: { type: 'text', value: '', desc: '심리스 사용시 전송 결과(finish, error)', required: false },
        beginDate: { type: 'date', value: null, desc: this.$t('text.api.beginDate_desc'), required: true },
        endDate: { type: 'date', value: null, desc: this.$t('text.api.endDate_desc'), required: false },
        limit: { type: 'number', value: '100', desc: this.$t('text.api.limit_desc'), required: true }
      }
    };
  },
  mounted() {
    const host = process.env.VUE_APP_API_SERVER_URL
    /*
    let host = location.host
    if (host.indexOf('localhost') > -1 || host.indexOf('127.0.0.1') > -1) {
      host = 'http://127.0.0.1:39011/'
    } else {
      host = `https://api.${host}/`
    }
     */
    this.apiUrl = host + 'transactions'

    // 응답값 설정
    this.response = '{\n' +
        ' "code": 0, \t\t\t\t\t\t\t// 응답값(0이 아니면 오류)\n' +
        ' "length": 333 \t\t\t\t\t\t\t// 갯수(최대 2,000개),\n' +
        ' "beginDate": "2023-05-11T10:20:00.455Z", \t\t\t// 다음 검색 시작일자(UTC Time = 한국시간 - 9시간 )\n' +
        ' "transactions": [ \t\t\t\t\t\t// 목록\n' +
        '  {\n' +
        '   "_id": "8705a14108be23b336da1c8x",\n' +
        '   "transactionKey": "d5f8324460fa98db3casw23b8f297fd0c", \t// 고유키\n' +
        '   "gameCategoryKey": "casino", \t\t\t\t\t// 게임종류\n' +
        '   "gameKey": "bc.01", \t\t\t\t\t// 게임코드\n' +
        '   "gameName": "Baccarat 1", \t\t\t// 게임명\n' +
        '   "gameTypeKey": "baccarat" \t\t\t\t\t// 게임형식,\n' +
        '   "isBonus": false, \t\t\t\t\t\t// 보너스 여부\n' +
        '   "isJackpot": false, \t\t\t\t\t\t// 잭팟 여부\n' +
        '   "isPromo": false, \t\t\t\t\t\t// 프로모션 여부\n' +
        '   "isFreeGame": false, \t\t\t\t\t// 프리게임 여부\n' +
        '   "groupKey": "", \t\t\t\t\t\t// 사이트에서 지정한 그룹코드\n' +
        '   "amount": 200, \t\t\t\t\t\t// 금액\n' +
        '   "createdAt": "2023-05-11T10:23:41.425Z", \t\t\t// 처리 시간(UTC Time)\n' +
        '   "requestedAt": "2023-05-11T10:23:41.625Z", \t\t\t// 요청 받은 시간(UTC Time)\n' +
        '   "username": "abc_user100", \t\t\t\t\t// API용 회원코드\n' +
        '   "siteUsername": "user100" \t\t\t\t\t// 사이트 회원코드,\n' +
        '   "type": "bet", \t\t\t\t\t\t// 거래형식(bet, win, lose, draw, cancel)\n' +
        '   "vendorKey": "vegas_casino", \t\t\t\t// 게임사 코드\n' +
        '   "key": "6305a1431630f63ddf4cd5ff" \t\t\t\t// 게임 본래 코드,\n' +
        '   "parentTransactionKey": null \t\t\t\t// 부모 코드\n' +
        '   "seamlessState": finish \t\t\t\t// 심리스인 경우 처리 상태코드(error, finish)\n' +
        '  },\n' +
        '  .\n' +
        '  .\n' +
        '  .\n' +
        ' ]\n' +
        '}'
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title"/>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="card-title"><span class="badge bg-primary">요청 URL</span> {{ apiUrl }}</div>
            <div class="card-title-desc">{{ $t('text.api_transactions_desc')}}</div>
            <hr/>
            <Document :data="body" :apiUrl="apiUrl" :response="response" :require-vendors="false" :require-ip-address="false" :require-request-key="false" />
          </div>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
